/* eslint-disable func-names */
const isVerticallyVisible = (el, wiggleRoom) => {
  const getHeight = () => window.innerHeight || document.documentElement.clientHeight;

  // expend viewport by this # of pixels to match "close-enough" elements
  const extraSpace = wiggleRoom || 0;

  const rect = el.getBoundingClientRect();
  if (rect.top === 0 && rect.bottom === 0) return false;

  return rect.top > (extraSpace * -1)
    && rect.bottom < getHeight() + extraSpace;
};

Dribbble.LargeShotViewRecorder = {
  start() {
    let scrollTimer;

    // clear any existing scroll handlers
    $(window).off('scroll.largeShotViewRecorder');

    $(window).on('scroll.largeShotViewRecorder', () => {
      // clear the timeout on scroll. We don't want it to fire until
      // scrolling stops.
      clearTimeout(scrollTimer);

      scrollTimer = setTimeout(() => {
        // now that scrolling has stopped, let's see what the user
        // is looking at
        this.considerShotsViewed();
      }, 500);
    });

    // consider initially displayed shots as viewed.
    setTimeout(this.considerShotsViewed.bind(this), 1000);
  },

  getScreenshotsInViewPort() {
    return $('.js-thumbnail[id^=screenshot-]').filter(function () {
      return isVerticallyVisible(this, 30);
    });
  },

  considerShotsViewed() {
    const $shotsInViewport = this.getScreenshotsInViewPort();

    $shotsInViewport.each((i, el) => {
      const id = parseInt(el.id.split('-')[1], 10);
      Dribbble.Shots.logView(id);
    });
  },
};

Dribbble.LargeShotViewRecorder.start();
